import type { Site } from 'src/shared/types';

export type Brand = 'Jobsdb' | 'Jobstreet' | 'SEEK';

export const brandLookup = (site: Site): Brand => {
  switch (site) {
    case 'employer-seek-hk':
    case 'employer-seek-th':
      return 'Jobsdb';
    case 'employer-seek-sg':
    case 'employer-seek-id':
    case 'employer-seek-ph':
    case 'employer-seek-my':
      return 'Jobstreet';
    default:
      return 'SEEK';
  }
};
