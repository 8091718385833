export const TRACKING_CODES = {
  advertising_term_of_use_link_clicked: 'advertising-term-of-use-link-clicked',
  concierge_link_clicked: 'enquire-concierge-link-clicked',
  recruiter_network_link_clicked: 'enquire-recruiter-network-link-clicked',
  ad_budgets_link_clicked: 'ad-budgets-link-clicked',
  ads_terms_link_clicked: 'ads-terms-link-clicked',
  ad_center_link_clicked: 'ad-center-link-clicked',
  ad_price_lookup_link_clicked: 'ad-price-lookup-link-clicked',
  article_link_clicked: 'article-link-clicked',
  bugcrowd_link_clicked: 'bugcrowd-link-clicked',
  certsy_link_clicked: 'certsy-link-clicked',
  clear_browser_history_link_clicked: 'clear-browser-history-link-clicked',
  community_guidelines_link_clicked: 'community-guidelines-link-clicked',
  connected_partners_clicked: 'connected-partners-clicked',
  contact_us_clicked: 'contact-us-clicked',
  company_reviews_information_pack_downloaded:
    'company-reviews-information-pack-downloaded',
  company_profiles_terms_link_clicked: 'company-profiles-terms-link-clicked',
  customer_service_phone_number_clicked:
    'customer-service-phone-number-clicked',
  customer_service_email_clicked: 'customer-service-email-clicked',
  dev_portal_terms_link_clicked: 'dev-portal-terms-link-clicked',
  employer_home_link_clicked: 'employer-home-link-clicked',
  help_centre_clicked: 'help-centre-clicked',
  help_content_guidelines_link_clicked: 'help-content-guidelines-link-clicked',
  jobs_ads_link_clicked: 'jobs-ads-link-clicked',
  jobseeker_contact_us_clicked: 'jobseeker-contact-us-clicked',
  mfa_help_centre_link_clicked: 'mfa-help-centre-link-clicked',
  password_advice_link_clicked: 'password-advice-link-clicked',
  product_terms_conditions_link_clicked:
    'product-terms-conditions-link-clicked',
  sales_phone_clicked: 'sales-phone-clicked',
  scamwatch_link_clicked: 'scamwatch-link-clicked',
  seek_home_link_clicked: 'seek-home-link-clicked',
  seek_api_terms_link_clicked: 'seek-api-terms-link-clicked',
  sign_in_link_clicked: 'sign-in-link-clicked',
  signout_link_clicked: 'signout-link-clicked',
  sales_phone_number_clicked: 'sales_phone_number_clicked',
  templates_help_centre_link_clicked: 'templates-help-centre-link-clicked',
  terms_link_clicked: 'terms-link-clicked',
  talent_search_link_clicked: 'talent-search-link-clicked',
  talent_search_terms_link_clicked: 'talent-search-terms-link-clicked',
  talent_search_connect_terms_link_clicked:
    'talent-search-connect-terms-link-clicked',
  talent_search_offer_terms_link_clicked:
    'talent-search-offer-terms-link-clicked',
  rcsa_link_clicked: 'rcsa-link-clicked',
  link_your_software_link_clicked: 'link-your-software-link-clicked',
  content_element_pressed: 'content_element_pressed',
  error_page_displayed: 'error_page_displayed',
};

export const RECAPTCHA_KEY = {
  prod: '6LdwAKYUAAAAAGN9Svq7w2B0pOZWMrWbYD3GeQ8a',
  dev: '6LcJijEgAAAAAJGQWVLC6f9sNDz8rNYTM-7ds3j2',
};
