import { EmployerHeader, type AnalyticsEvent } from '@seek/adv-header-footer';
import { useHirerAuth } from '@seek/hirer-auth-react';
import { useAccountContext } from '@seek/hirer-graphql-react';
import camelCase from 'camelcase';
import type React from 'react';

import { useTracking } from 'src/shared/hooks';
import type {
  ActiveSubTab,
  ActiveTab,
  ActiveTabId,
  EmployerSiteName,
  ExcludedSubTabTypes,
  Language,
} from 'src/shared/types';

interface Props {
  basic?: boolean;
  activeTab: ActiveTab;
  activeSubTab?: ActiveSubTab;
  error?: any;
  site: EmployerSiteName;
  locale: Language;
  isStaging: boolean;
}

const AppHeader = ({
  basic,
  activeTab,
  activeSubTab,
  site,
  locale,
  isStaging,
  error,
}: Props) => {
  const { trackEvent } = useTracking();
  const { loading, authenticated } = useHirerAuth();
  const { accountContext } = useAccountContext();

  let headerFooterContext;
  if (!loading) {
    headerFooterContext = authenticated
      ? accountContext?.headerFooterContext
      : { identityContext: null }; // this is required to explicitly render the signed-out header state rather than undetermined.
  }

  const onToggleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventName = event.target.getAttribute('data-analytics');
    trackEvent(eventName!, {
      actionOrigin: 'global-header',
    });
  };

  const trackHeaderFooterInteractions = (analyticsEvent: AnalyticsEvent) => {
    trackEvent(analyticsEvent.eventName, { ...analyticsEvent });
  };

  return (
    <EmployerHeader
      // advertiser={advertiser}
      site={site}
      locale={locale}
      isStaging={isStaging}
      // identityContext={identityContext}
      // user={user}
      basic={basic}
      activeTabId={camelCase(activeTab || '') as ActiveTabId<ActiveTab>}
      error={error}
      activeSubTabId={
        camelCase(activeSubTab || '') as Exclude<
          ActiveTabId<ActiveSubTab>,
          ExcludedSubTabTypes
        >
      }
      onTrackedItemInteraction={trackHeaderFooterInteractions}
      onToggleButtonClick={onToggleButtonClick}
      {...headerFooterContext}
    />
  );
};

export default AppHeader;
