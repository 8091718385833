import { useContext } from 'react';

import configContext from '../config-context';
import type { Locale } from '../types';

export const useLocale = (): Locale => {
  const { locale } = useContext(configContext);
  return locale;
};

export const useEnvironment = () => {
  const { environment } = useContext(configContext);
  return environment;
};

export const useSite = () => {
  const { site } = useContext(configContext);
  return site;
};

export const useLanguage = () => {
  const { language } = useContext(configContext);
  return language;
};

export const useConfig = () => {
  const config = useContext(configContext);
  return config;
};
