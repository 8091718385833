import { useMelwaysLink } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';
import {
  Link as BraidLink,
  ButtonLink as BraidButton,
  TextLink as BraidTextLink,
} from 'braid-design-system';
import type React from 'react';

import { useConfig, useTracking } from 'src/shared/hooks';

export type LinkProductType = 'candidate' | 'employer' | 'other';

type LinkProps = React.ComponentProps<typeof BraidLink> & {
  tracking: string;
  trackingProps?: Record<string, any>;
  component?: typeof BraidLink | typeof BraidButton;
  product?: LinkProductType;
  country?: Country;
  skipResolvedUrl?: boolean; // Components like AppHeader and Footer from package have already resolved their link with language
};

export const Link = ({
  tracking,
  trackingProps,
  component: Component = BraidLink,
  children,
  href,
  product,
  country,
  skipResolvedUrl = false,
  ...restProps
}: LinkProps) => {
  const { language, country: siteCountry } = useConfig();
  const urlResolver = useMelwaysLink();
  const { trackEvent } = useTracking();

  /* Supplying product='other' skips the UrlResolver and creates an external or non-seek link. */
  const resolveHref = () => {
    if (product === 'other' || skipResolvedUrl) {
      return href;
    }

    return urlResolver({
      language,
      path: href,
      product,
      country: country || siteCountry,
    });
  };

  const onClick = () => {
    trackEvent(tracking, trackingProps);
  };

  const combinedProps = {
    onClick,
    href: resolveHref(),
    ...restProps,
  };

  return <Component {...combinedProps}>{children}</Component>;
};

export type ButtonProps = Omit<LinkProps, 'component'> &
  React.ComponentProps<typeof BraidButton>;

export const ButtonLink = (props: ButtonProps) => (
  <Link component={BraidButton} {...props} />
);

type TextLinkProps = Omit<LinkProps, 'component'> &
  React.ComponentProps<typeof BraidTextLink>;

export const TextLink = (props: TextLinkProps) => (
  <Link component={BraidTextLink} {...props} />
);

export default Link;
