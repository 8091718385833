import { useHirerAuth } from '@seek/hirer-auth-react';
import { Actions, Text, Stack } from 'braid-design-system';
import type React from 'react';

import { ButtonLink, TextLink } from 'src/components';
import { TRACKING_CODES } from 'src/shared/constant';
import { SIGN_IN } from 'src/shared/links';

interface Text {
  loggedIn: string;
  loggedOut: string;
}

interface Href {
  loggedIn: string;
  loggedOut: string;
}

interface TrackingProps {
  loggedIn: string;
  loggedOut: string;
}

interface CTAButtonTypes {
  text: Text;
  href: Href;
  variant?: React.ComponentProps<typeof ButtonLink>['variant'];
  tone?: React.ComponentProps<typeof ButtonLink>['tone'];
  center?: boolean;
  tracking: TrackingProps;
}

const CTAButton = ({
  text,
  href,
  tone,
  center,
  tracking,
  variant,
}: CTAButtonTypes) => {
  const { authenticated, loading } = useHirerAuth();
  const { loggedIn, loggedOut } = tracking;
  const authTracking = authenticated ? loggedIn : loggedOut;
  const buttonText = authenticated ? text.loggedIn : text.loggedOut;
  const link = authenticated ? href.loggedIn : href.loggedOut;

  return (
    <Stack space="gutter" align={center ? 'center' : 'left'}>
      <Actions>
        <ButtonLink
          tone={tone}
          variant={variant}
          tracking={authTracking}
          href={link}
          loading={loading}
        >
          {buttonText}
        </ButtonLink>
      </Actions>
      {!authenticated && (
        <Text>
          Already have an account?{' '}
          <TextLink
            href={SIGN_IN}
            tracking={TRACKING_CODES.sign_in_link_clicked}
          >
            Sign in
          </TextLink>
        </Text>
      )}
    </Stack>
  );
};

export default CTAButton;
