import { Box, ContentBlock, Divider } from 'braid-design-system';
import React from 'react';

export const ResponsiveDivider = () => (
  <ContentBlock>
    <Box paddingX={['small', 'small', 'none']}>
      <Divider />
    </Box>
  </ContentBlock>
);
