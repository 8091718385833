import { useHirerAuth } from '@seek/hirer-auth-react';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { Page } from 'braid-design-system';
import React, { useEffect, useMemo, type ReactNode } from 'react';
import Helmet from 'react-helmet';

import { useTracking } from 'src/shared/hooks/useTracking';
import type { ActiveTab, ActiveSubTab } from 'src/shared/types';
import { brandLookup } from 'src/utils/brandLookup';

import { useConfig } from '../../shared/hooks/';
import type { PageTrackingProps } from '../Tracking/trackingTypes';

import '../../shared/polyfills';

import AppFooter from './AppFooter/AppFooter';
import AppHeader from './AppHeader/AppHeader';

export interface StaticPageProviderProps extends PageTrackingProps {
  title: string;
  returnUrl: string;
  children: ReactNode;
  basic?: boolean;
  activeTab?: ActiveTab;
  activeSubTab?: ActiveSubTab;
  hideHeader?: boolean;
  hideFooter?: boolean;
  shouldTrack?: boolean;
  description?: string;
  index?: boolean;
  follow?: boolean;
  enableHreflang?: boolean;
}

const capitaliseFirstLetter = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const StaticPageProvider = ({
  title,
  children,
  basic,
  activeTab,
  eventName,
  activeSubTab,
  siteSection,
  subSection,
  hideHeader,
  hideFooter,
  currentPage,
  linkName,
  jobId,
  errorMessage,
  shouldTrack = true,
  description,
  index,
  follow,
  enableHreflang,
}: StaticPageProviderProps) => {
  const {
    siteLanguage,
    site,
    country,
    language,
    environment,
    zone,
    hostname,
    route,
    classification,
  } = useConfig();
  const { authenticated, loading } = useHirerAuth();
  const { accountContext } = useAccountContext();
  const brand = brandLookup(site).toLocaleLowerCase();
  const { trackView } = useTracking();

  const isStaging = environment === 'staging';
  const headerFooterProps = {
    site,
    locale: language,
    isStaging,
  };

  const pageTrackingProps = useMemo(
    () => ({
      eventName,
      siteSection,
      siteCountry: country,
      seekAdvertiserId: accountContext?.currentAdvertiser?.billingId,
      userId: accountContext?.currentUser?.id,
      isLoggedIn: authenticated,
      ...(subSection && { subSection }),
      ...(brand && { brand }),
      ...(currentPage && { currentPage }),
      ...(siteLanguage && { siteLanguage }),
      ...(linkName && { linkName }),
      ...(jobId && { jobId }),
      ...(zone && { zone }),
      ...(errorMessage && { errorMessage }),
    }),
    [
      eventName,
      siteSection,
      subSection,
      currentPage,
      siteLanguage,
      country,
      brand,
      linkName,
      jobId,
      zone,
      accountContext,
      authenticated,
      errorMessage,
    ],
  );

  const meta = useMemo(() => {
    const generalMeta = [{ name: 'description', content: description }];

    const robotsValues: Record<string, boolean> = {
      index: Boolean(index),
      follow: Boolean(follow),
    };

    // Reduce the values to a string[]
    const robotMeta = Object.entries(robotsValues).reduce<string[]>(
      (carry, [key, value]) => (value ? [...carry, key] : carry),
      [],
    );

    if (robotMeta.length) {
      generalMeta.push({ name: 'robots', content: robotMeta.join(',') });
    }

    return [...generalMeta].filter((metaData) => Boolean(metaData.content));
  }, [description, follow, index]);

  useEffect(() => {
    if (!loading) {
      const authTrackingProps = {
        userId: accountContext?.currentUser?.id,
        seekAdvertiserId: accountContext?.currentAdvertiser?.billingId,
      };

      const combinedProps = {
        ...pageTrackingProps,
        ...authTrackingProps,
      };

      if (typeof window !== undefined) {
        window.currentPageTrackingData = combinedProps;
      }
      if (shouldTrack) {
        trackView(pageTrackingProps.page);
      }
    }
  }, [authenticated, accountContext, pageTrackingProps]); // eslint-disable-line react-hooks/exhaustive-deps

  let routeValueFiltered = route.replace('/id/', '').replace('/th/', '');
  if (routeValueFiltered.startsWith('/')) {
    routeValueFiltered = routeValueFiltered.substring(1);
  }

  const hreflangs = [
    {
      hreflang: 'en-my',
      href: `https://my.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-sg',
      href: `https://sg.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-ph',
      href: `https://ph.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-id',
      href: `https://id.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'id-id',
      href: `https://id.employer.seek.com/id/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-hk',
      href: `https://hk.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-th',
      href: `https://th.employer.seek.com/${routeValueFiltered}`,
    },
    {
      hreflang: 'th-th',
      href: `https://th.employer.seek.com/th/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-au',
      href: `https://talent.seek.com.au/${routeValueFiltered}`,
    },
    {
      hreflang: 'en-nz',
      href: `https://talent.seek.co.nz/${routeValueFiltered}`,
    },
  ];
  let canonUrl;
  switch (siteLanguage) {
    case 'en':
      canonUrl = `https://${hostname}/${routeValueFiltered}`;
      break;
    default:
      canonUrl = `https://${hostname}/${siteLanguage}/${routeValueFiltered}`;
      break;
  }

  return (
    <Page
      footer={
        hideFooter ? null : (
          <AppFooter {...headerFooterProps} {...accountContext} />
        )
      }
    >
      {hideHeader ? null : (
        <AppHeader
          basic={basic}
          activeTab={activeTab}
          activeSubTab={activeSubTab}
          // error={error}
          {...headerFooterProps}
          {...accountContext}
        />
      )}

      <Helmet
        meta={meta}
        title={`${title} | ${classification.brand.toUpperCase()} ${capitaliseFirstLetter(
          classification.product,
        )}`}
      >
        {enableHreflang &&
          hreflangs.map((e, num) => <link rel="alternate" key={num} {...e} />)}
        {(routeValueFiltered === 'contactus' ||
          site === 'employer-seek-id') && (
          <link rel="canonical" href={canonUrl} />
        )}
        {routeValueFiltered === 'clear-browser-history' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>

      {children}
    </Page>
  );
};

export default StaticPageProvider;
