import { adapter } from '@seek/tealium-adapter';
import { Box, Disclosure } from 'braid-design-system';
import React, { useState, useEffect, type FC, type ReactElement } from 'react';

const tealium = adapter();

interface AccordionProps {
  title: string;
  children: ReactElement[] | ReactElement;
  linkName: string;
}

const Accordion: FC<AccordionProps> = ({ title, children, linkName }) => {
  const [checked, setChecked] = useState(false);
  const onClick = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (checked) {
      const analytics = {
        action: 'expanded',
        currentPage: 'protect-yourself-online',
        siteSection: 'security',
        linkName,
      };
      tealium.link(linkName, analytics);
    }
  });
  return (
    <Box paddingY="xsmall">
      <Disclosure
        collapseLabel={title}
        expandLabel={title}
        expanded={checked}
        id={title}
        onToggle={onClick}
        space="medium"
      >
        {children}
      </Disclosure>
    </Box>
  );
};

export default Accordion;
