type Source = 'brightcove' | 'youtube';

export const resolveSource = (source: Source, id: string): string => {
  switch (source) {
    case 'youtube':
      return `https://www.youtube.com/embed/${id}?modestbranding=1&rel=0&showinfo=0`;
    default:
      return `https://players.brightcove.net/6040621522001/qx6UKDb3d_default/index.html?videoId=${id}`;
  }
};
