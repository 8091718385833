import { EmployerFooter, type AnalyticsEvent } from '@seek/adv-header-footer';
import { useHirerAuth } from '@seek/hirer-auth-react';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { Box, Loader } from 'braid-design-system';
import React from 'react';

import { useTracking } from 'src/shared/hooks';
import type { EmployerSiteName, Language } from 'src/shared/types';

interface Props {
  site: EmployerSiteName;
  locale: Language;
  isStaging: boolean;
}

const AppFooter = ({ site, locale, isStaging }: Props) => {
  const { loading, authenticated } = useHirerAuth();
  const { accountContext } = useAccountContext();
  const { trackEvent } = useTracking();

  /* Display a blank footer state if user auth is pending */
  if (loading) {
    return (
      <Box width="full" background="neutralLight" style={{ height: '98px' }}>
        <Loader />
      </Box>
    );
  }

  let headerFooterContext;
  if (!loading) {
    headerFooterContext = authenticated
      ? accountContext?.headerFooterContext
      : { identityContext: null }; // this is required to explicitly render the signed-out header state rather than undetermined.
  }

  const trackHeaderFooterInteractions = (analyticsEvent: AnalyticsEvent) => {
    trackEvent(analyticsEvent.eventName, { ...analyticsEvent });
  };

  return (
    <EmployerFooter
      // advertiser={advertiser}
      site={site}
      locale={locale}
      isStaging={isStaging}
      // identityContext={identityContext}
      onTrackedItemInteraction={trackHeaderFooterInteractions}
      {...headerFooterContext}
    />
  );
};

export default AppFooter;
