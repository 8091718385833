import type { Locale, RequireAtLeastOne } from 'src/shared/types';

import { useLocale } from './useContext';

export type LocaleContent<T> = { AU: T } & RequireAtLeastOne<Record<Locale, T>>;

export const useLocalized = <T,>(content: LocaleContent<T>): T => {
  const locale = useLocale();
  const localizedContent = content[locale];

  /**
   * Fallback to 'AU' if requested region content isnt provided.
   * This means that 'AU' must be provided, even if it's `null`
   */
  if (localizedContent === undefined) {
    return content.AU;
  }

  return localizedContent;
};
