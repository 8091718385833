import {
  URL_PRODUCTS_JOB_ADS,
  URL_PRODUCTS_BRANDING,
  URL_PRODUCTS_CANDIDATE_MANAGEMENT,
  URL_PRODUCTS_COMPANY_REVIEWS,
  URL_PRODUCTS_GUARANTEED_HIRE,
  URL_PRODUCTS_TALENT_SEARCH,
  URL_PRODUCTS_HUB,
  URL_PRODUCTS_AD_BUDGETS,
  URL_CONTACTUS,
  URL_SIGNIN,
  URL_ACCOUNT_BRANDING,
  URL_ACCOUNT_REGISTRATION,
  URL_SUPPORT_TERMS,
  URL_PRODUCT_TERMS,
  URL_TALENT_SEARCH_TERMS,
  URL_ACCOUNT_AD_PRICE_LOOKUP,
  URL_BLOG_MARKET_INSIGHTS_ROOT,
  URL_BLOG_HIRING_ADVICE_ROOT,
  URL_CREATE_JOB,
} from '@seek/adv-constants';

export const AD_BUDGETS = URL_PRODUCTS_AD_BUDGETS;
export const JOB_ADS = URL_PRODUCTS_JOB_ADS;
export const AD_CENTRE = URL_PRODUCTS_CANDIDATE_MANAGEMENT;
export const COMPANY_REVIEWS = URL_PRODUCTS_COMPANY_REVIEWS;
export const TALENT_SEARCH = URL_PRODUCTS_TALENT_SEARCH;
export const BRANDING = URL_PRODUCTS_BRANDING;
export const GUARANTEED_HIRE = URL_PRODUCTS_GUARANTEED_HIRE;
export const CONTACT_US = URL_CONTACTUS;
export const SIGN_IN = URL_SIGNIN;
export const REGISTER = URL_ACCOUNT_REGISTRATION;
export const CREATE_JOB = URL_CREATE_JOB;
export const TERMS = URL_SUPPORT_TERMS;
export const COMMUNITY_GUIDELINES_AU =
  'https://help.seek.com.au/s/article/Company-reviews-community-guidelines';
export const COMMUNITY_GUIDELINES_NZ =
  'https://help.seek.co.nz/s/article/Company-reviews-community-guidelines';
export const PRODUCT_TERMS = URL_PRODUCT_TERMS;
export const TALENT_SEARCH_TERMS = URL_TALENT_SEARCH_TERMS;
export const ACCOUNT_BRANDING = URL_ACCOUNT_BRANDING;
export const CONCIERGE_URL =
  '/job/managejob/express/create/classify?referrer=product_page';
export const RECRUITER_NETWORK_URL =
  'https://talent.seek.com.au/recruiter-network';
export const CONNECTED_PARTNERS = '/partners/connected-partners';
export const TALENT_SEARCH_CONNECT_TERMS = '/support/talentsearchconnectterms/';
export const TALENT_SEARCH_OFFER_TERMS = '/support/talentsearchofferterms/';
export const COMPANY_PROFILE_TERMS = '/support/companyprofilesterms/';
export const AD_PRICE_LOOKUP = URL_ACCOUNT_AD_PRICE_LOOKUP;
export const MARKET_INSIGHTS = URL_BLOG_MARKET_INSIGHTS_ROOT;
export const HIRING_ADVICE = URL_BLOG_HIRING_ADVICE_ROOT;
export const PRODUCT_HUB = URL_PRODUCTS_HUB;
export const DEVELOPER_SITE = 'https://developer.seek.com/';
export const DEVELOPER_SITE_TERMS =
  'https://developer.seek.com/introduction/terms-of-use';
export const DEVPORTAL = 'https://devportal.seek.com.au/';
export const DEVPORTAL_TERMS = 'https://devportal.seek.com.au/terms/overview';
export const API_TERMS = '/partners/terms-of-use';
export const COMPANY_PROFILES_ENQUIRY = '/products/company-profiles/enquire';
