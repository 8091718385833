/* For use when tracking an event eg., page view, form submit */
import { adapter } from '@seek/tealium-adapter';

const tealium = adapter();

const getPageTrackingData = () => {
  if (typeof window !== 'undefined') {
    return window.currentPageTrackingData;
  }
  return {};
};

export const useTracking = () => {
  const trackEvent = (
    eventName: string,
    eventProperties?: Record<string, any>,
  ) => {
    const currentPageTrackingData = {
      ...getPageTrackingData(),
      eventName,
      linkName: eventName,
    };

    tealium.link(eventName, {
      ...currentPageTrackingData,
      ...eventProperties,
    });

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('TRACKING EVENT: ', {
        ...currentPageTrackingData,
        ...eventProperties,
      });
    }
  };

  const trackView = (pageName: string) => {
    const currentPageTrackingData = {
      ...getPageTrackingData(),
    };

    tealium.view(pageName, currentPageTrackingData);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('TRACKING EVENT: ', currentPageTrackingData);
    }
  };
  return { trackEvent, trackView };
};
