import {
  Actions,
  ContentBlock,
  Box,
  Stack,
  Heading,
  Text,
  Columns,
  Column,
  IconChevron,
} from 'braid-design-system';
import React, { type ComponentProps } from 'react';
import reactHtmlParser from 'react-html-parser';

import {
  ButtonLink,
  TextLink,
  Video,
  type LinkProductType,
} from 'src/components';

import * as styles from './FeatureBlock.css';

const CustomLink = ({ data }: { data: CtaProps }) =>
  data.component === 'Text' ? (
    <Text>
      <TextLink
        href={data.href}
        tracking={data.tracking}
        product={data.product}
      >
        {data.text} <IconChevron direction="right" />
      </TextLink>
    </Text>
  ) : (
    <Box paddingTop={['gutter', 'small']}>
      <Actions>
        <ButtonLink
          href={data.href}
          tracking={data.tracking}
          product={data.product}
        >
          {data.text}
        </ButtonLink>
      </Actions>
    </Box>
  );

interface CtaProps {
  text: string;
  href: string;
  product?: LinkProductType;
  component?: 'Text' | 'Button';
  tracking: string;
}

export const FeatureBlock = ({
  tagline,
  title,
  level = '1',
  text = 'Lorem ipsum dorem',
  image,
  quote,
  videoId,
  cta,
  background = 'none',
  reverse,
}: {
  tagline?: string;
  title?: string;
  level?: ComponentProps<typeof Heading>['level'];
  text: string;
  videoId?: string;
  quote?: JSX.Element;
  image?: string;
  cta?: CtaProps;
  background?: string;
  reverse?: boolean;
  list?: [string];
}) => (
  <Box style={{ backgroundColor: background }} paddingY={['large', 'none']}>
    <ContentBlock>
      <Columns
        space={['large', 'xlarge']}
        reverse={!reverse}
        alignY="center"
        collapseBelow="tablet"
      >
        <Column width={videoId ? '3/5' : '1/2'}>
          {quote ? (
            <Stack space="none" align="center">
              {quote}
            </Stack>
          ) : null}
          {videoId ? (
            <Box width="full" className={styles.image}>
              <Video id={videoId} />
            </Box>
          ) : null}
          {image ? (
            <Box>
              <Box
                width="full"
                borderRadius="large"
                className={styles.image}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            </Box>
          ) : null}
        </Column>
        <Column width={videoId ? '2/5' : '1/2'}>
          <Box
            paddingY={['none', 'large', 'xxlarge']}
            paddingX={['gutter', 'gutter', 'none']}
          >
            <Stack space="medium" align="left">
              {tagline ? (
                <Text tone="brandAccent">
                  <span style={{ color: '#6b40cd' }}>{tagline}</span>
                </Text>
              ) : null}
              {title ? (
                <Heading level={level}>{reactHtmlParser(title)}</Heading>
              ) : null}
              <Text tone="secondary">{reactHtmlParser(text)}</Text>
              {cta ? <CustomLink data={cta} /> : null}
            </Stack>
          </Box>
        </Column>
      </Columns>
    </ContentBlock>
  </Box>
);
